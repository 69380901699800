import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, catchError, tap, throwError } from "rxjs";
import { AuthService } from "../../../core/services/auth.service";
import { LocalStorageService } from "../../../core/services/local-storage.service";
import { AuthResponseCustom } from "../../../shared/interfaces/auth.interface";
import { Authenticate, Logout } from "./auth.actions";
import { AuthStateModel } from "./auth.model";

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    loading: false,
    auth: null,
  }
})
@Injectable({ providedIn: 'root' })
export class AuthState {

  jwtHelper = new JwtHelperService();

  @Selector()
  static loading(state: AuthStateModel): boolean | undefined {
    return state.loading;
  }

  @Selector()
  static auth(state: AuthStateModel): AuthResponseCustom | null {
    return state.auth;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.auth;
  }

  @Selector()
  static accessToken(state: AuthStateModel): string | undefined {
    return state.auth?.token;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService) { }


  @Action(Authenticate, { cancelUncompleted: true })
  authenticate(ctx: StateContext<AuthStateModel>, action: Authenticate): Observable<AuthResponseCustom> {
    ctx.patchState({ loading: true });
    

    return this.authService.login(action.payload).pipe(
      tap((auth: AuthResponseCustom) => {

        if (auth.authInfo) {
          const decodeToken = this.jwtHelper.decodeToken(auth.token);
          auth.authInfo.ci = decodeToken['User'];
          auth.authInfo.username = decodeToken['Name'];
        }

        ctx.patchState({ auth: auth, loading: false });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(Logout, { cancelUncompleted: true })
  logout(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({
      loading: false
    });

    this.authService.logout();
  }
}