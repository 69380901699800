import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Testimonial } from '../../features/landing/interfaces/landing-data.interface';

@Injectable({ providedIn: 'root' })
export class TestimonialsService {
  constructor(private httpClient: HttpClient) { }

  getTestimonials(): Observable<Testimonial[]> {
    //return this.httpClient.get(`${environment.identity}/api/account/my-profile`);

    return of([
      {
        id: 1,
        title: "Recibir puntos por cada compra y luego canjearlos por descuentos me hace sentir realmente valorado como cliente."
      },
      {
        id: 2,
        title: "Me encanta lo fácil que es acumular puntos y recibir recompensas.¡Está genial ser recompensada por simplemente ser un cliente fiel!"
      },
      {
        id: 3,
        title: "Beneficiate simplificó completamente mi experiencia de compra. Ahora puedo tener un registro de mis puntos y canjearlos fácilmente por descuentos en las tiendas asociadas. ¡Así da gusto ser un cliente fiel!"
      },
      {
        id: 4,
        title: "La transparencia y facilidad de Beneficiate hacen que ser un cliente fiel sea aún más gratificante. Me encanta poder llevar un seguimiento rápido y sencillo de mis puntos acumulados en cada tienda, todo en un solo lugar."
      },
      {
        id: 5,
        title: "Saber que estos puntos se traducen en descuentos no solo en mi tienda favorita, sino también en otras tiendas asociadas, hace que cada compra sea una experiencia emocionante."
      },
      {
        id: 6,
        title: "Gracias a Beneficiate, ser un cliente fiel va más allá de recibir recompensas básicas. La capacidad de acceder fácilmente a mi cuenta y conocer instantáneamente mis puntos acumulados en todas las tiendas asociadas me da la confianza de que mis lealtades están siendo recompensadas."
      }
    ]);
  }
}