import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Faq } from '../../features/landing/interfaces/landing-data.interface';

@Injectable({ providedIn: 'root' })
export class FaqsService {
  constructor(private httpClient: HttpClient) { }

  getFaqs(): Observable<Faq[]> {
    //return this.httpClient.get(`${environment.identity}/api/account/my-profile`);

    return of([
      {
        id: 1,
        title: '¿Cómo funciona Beneficiate?',
        description: 'Beneficiate es un programa de fidelización que te permite acumular puntos cada vez que realizas una compra en las tiendas adheridas. Con esos puntos, podrás canjear cupones de descuento o acceder a beneficios exclusivos en las tiendas.',
        open: false
      },
      {
        id: 2,
        title: '¿Cómo acumulo Puntos?',
        description: 'Cada vez que realizas una compra en una tienda adherida, puedes acumular puntos de varias formas. Puedes registrar tu compra subiendo una foto de la factura en la sección "Registrar Compra" dentro de nuestra web o el cajero de la tienda puede registrar tu compra directamente en Beneficiate utilizando tu número de cédula. Si la tienda cuenta con Beneficiate integrado en su e-commerce, verás un modal en la esquina inferior derecha para que puedas registrarte y comenzar a sumar puntos automáticamente al hacer una compra online.',
        open: false
      },
      {
        id: 3,
        title: '¿Cómo funcionan los puntos y los sellos en Beneficiate?',
        description: 'Algunas tiendas en Beneficiate tienen diferentes modalidades de acumulación: puntos o sellos. Si la tienda utiliza el sistema de puntos, cada vez que realizas una compra acumulas puntos que luego puedes canjear por cupones de descuento, obtener un porcentaje de descuento en futuras compras, o incluso cambiar cada punto por un peso en tu próxima compra. Por otro lado, si la tienda utiliza el sistema de sellos, se acumulan sellos cada vez que compras un producto específico. Cuando alcanzas una cantidad definida de sellos, puedes acceder a un beneficio o recompensa, como un producto gratis o un descuento en la tienda',
        open: false
      },
      {
        id: 4,
        title: '¿Cómo canjeo mis puntos?',
        description: 'Una vez que hayas acumulado puntos suficientes, podrás canjearlos por cupones de descuento o utilizarlos como crédito para futuras compras. Desde nuestra web, en la sección "Mis Puntos", podrás consultar los cupones disponibles y canjearlos directamente en la tienda utilizando tu CI. En el caso de un Beneficio Cruzado, el canje se debe realizar desde la sección "Promociones"',
        open: false
      },
      {
        id: 5,
        title: '¿Qué son los Beneficios Cruzados?',
        description: 'Los Beneficios Cruzados te permiten utilizar los puntos acumulados en una tienda para canjear descuentos o beneficios en otras tiendas asociadas a Beneficiate. Esto significa que, con los puntos que ganaste en una tienda, puedes acceder a promociones y cupones de otra tienda. Para canjear un Beneficio Cruzado, simplemente accede a la sección "Promociones" en nuestra web y elige la oferta que más te convenga. Esta es una excelente forma de descubrir nuevas tiendas y aprovechar al máximo tus puntos.',
        open: false
      },
      {
        id: 6,
        title: '¿Cómo sé cuántos puntos tengo disponibles?',
        description: 'Puedes añadir una pregunta que explique cómo el usuario puede consultar su saldo de puntos. Podrías mencionar la sección "Mis Puntos" en la web, o que reciben notificaciones por correo o WhatsApp.',
        open: false
      },
      {
        id: 7,
        title: '¿Los puntos que acumulo en una tienda física son iguales que los del e-commerce?',
        description: 'Los puntos que acumulas en una tienda física son los mismos que acumulas en el e-commerce de esa tienda. Es decir, puedes sumar puntos comprando tanto en la tienda física como en el comercio online, y utilizarlos indistintamente en cualquiera de las dos. Esto te da la flexibilidad de canjear tus puntos ya sea en persona o a través de la plataforma de e-commerce de la tienda adherida a Beneficiate.',
        open: false
      }

    ]);
  }
}