import { Dialog } from "@angular/cdk/dialog";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, catchError, of, throwError } from "rxjs";
import { Logout } from "../../features/auth/state/auth.actions";
import { AuthState } from "../../features/auth/state/auth.state";
import { StatusModalComponent } from "../../shared/components/status-modal/status-modal.component";

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private dialog: Dialog, private router: Router, private ngZone: NgZone, private store: Store) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 || err.error.error.message === 'Token expired') {
          // Maneja el caso de token expirado=
          this.router.navigate(['/auth/login']);
          return throwError(() => err); // Termina el flujo aquí sin pasar al modal
        } else {
          let errData: { code: string | number; message: string } = {
            code: '',
            message: 'Ocurrió un error, vuelva a iniciar sesión'
          };
          const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

          if (isAuthenticated) {
            errData = this.handlerErrorBlock(err, errData);
          } else {
            errData = this.handlerErrorBlock(err, errData);
          }

          this.dialog.open(StatusModalComponent, {
            width: '400px',
            data: {
              title: 'Error',
              icon: 'ph-x',
              iconClass: 'bg-ms-error-600',
              message: errData.message,
              confirmBtnText: 'Close'
            }
          });
        }

        return throwError(() => err);
      })
    );
  }

  unauthorizedBlock(): void {
    this.ngZone.runOutsideAngular(() => {
      this.store.dispatch(new Logout());
      this.router.navigate(['/auth/login']);
    });
  }

  handlerErrorBlock(err: HttpErrorResponse, errData: any): any {
    if (err.error != null) {
      if (err.error instanceof ErrorEvent) {
        // Client side
        errData.code = err.status;
        errData.message = err.message;
      } else {
        // Server side
        if (err.error?.details) {
          errData.message = err.error.details;
        } else if (err.error?.error_description) {
          errData.message = err.error.error_description;
        } else {
          const error = err.error;
          errData.message = error.error ? error.error?.message : 'Ocurrió un error';
        }
      }
    }
    return errData;
  }

}